import { CircularProgress, SvgIcon } from "@mui/material"
import React from "react"

const LoadingIndicator = () => {
  return (
    <>
      <CircularProgress color={"inherit"} size={20} sx={{ mr: 2 }} />
    </>
  )
}

export default LoadingIndicator
