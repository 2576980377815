import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Step from "@mui/material/Step"
import Stepper from "@mui/material/Stepper"
import * as React from "react"
import { useEffect, useState } from "react"

const Steps = props => {
  const { idealWeightMin, idealWeightMax, maxOverweight, maxObeseWeight } =
    props

  const [steps, setSteps] = useState([
    idealWeightMin,
    idealWeightMax,
    maxOverweight,
    maxObeseWeight,
  ])

  const [isMetric, setIsMetric] = useState(props.isMetric)

  useEffect(() => {
    const newSteps = []
    newSteps.push(idealWeightMin)
    newSteps.push(idealWeightMax)
    newSteps.push(maxOverweight)
    newSteps.push(maxObeseWeight)
    setSteps(newSteps)
    setIsMetric(props.isMetric)
  }, [props, idealWeightMax, idealWeightMin, maxObeseWeight, maxOverweight])
  return (
    <Box sx={{ width: "95%" }}>
      <Stepper nonLinear>
        {steps.map((label, i) => (
          <Step key={i}>
            <Typography component="span" variant="body2">
              {`${label} ${isMetric ? "kg" : "lbs"}`}
            </Typography>
          </Step>
        ))}
      </Stepper>
    </Box>
  )
}

export default Steps
