import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import * as React from "react"
import theme from "../theme"
import withTrans from "./withTrans"

const Bubble = ({
  color,
  idealWeightMin,
  idealWeightMax,
  bmi,
  isMetric,
  type,
  t,
}) => {
  return (
    <Box component="div" sx={{ position: "relative" }}>
      <Box
        className="bubble"
        sx={{
          color: theme.palette.text.light,
          width: "auto",
          backgroundColor: color,
          borderRadius: 2,
          p: theme.spacing(1),
          px: theme.spacing(2),
          mb: theme.spacing(3),
          textAlign: "center",
          display: "inline-block",
          "&:after": {
            borderColor: `${color} transparent`,
          },
        }}
      >
        <Typography variant="body2" sx={{}}>
          {type === "bmi" ? (
            <>
              <strong>{t("calculator.bmi.label.your_bmi")}</strong>
              <br />
              {bmi}
            </>
          ) : (
            <>
              <strong>
                {t("calculator.general.label.ideal_weight_range")}
              </strong>
              <br />
              {`${idealWeightMin} ${
                isMetric ? "kg" : "lbs"
              }  - ${idealWeightMax} ${isMetric ? "kg" : "lbs"}`}
            </>
          )}
        </Typography>
      </Box>
    </Box>
  )
}

export default withTrans(Bubble)
