import styled from "@emotion/styled"
import {
  Alert,
  alpha,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material"
import i18next, { t } from "i18next"
import React, { useEffect, useState } from "react"
import PageHeadingIcon from "../assets/images/icons/scale-green.svg"
import AdAppCounting from "../components/AdAppCounting"
import CalcInputBox from "../components/CalcInputBox"
import GenderSwitch from "../components/GenderSwitch"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import RangeBar from "../components/RangeBar"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import TextInput from "../components/TextInput"
import withTrans from "../components/withTrans"
import theme from "../theme"
import LoadingIndicator from "../components/LoadingIndicator"

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.main,
  disableElevation: true,
  borderColor: theme.palette.success.dark,
  "&.MuiButton-contained": {},
  "&.MuiButton-outlined": {
    border: `1px solid #D0D5DD`,
    backgroundColor: alpha(theme.palette.success.light, 0.1),
  },
  "&:hover": {
    backgroundColor: alpha(theme.palette.success.light, 1),
  },
}))

const CalcIdealWeight = () => {
  const upMd = useMediaQuery(theme.breakpoints.up("md"))

  // errors
  const [errors, setErrors] = useState([])

  // inputs
  const [heightCm, setHeightCm] = useState("")
  const [heightFt, setHeightFt] = useState("")
  const [heightIn, setHeightIn] = useState("")
  const [unitHeight, setUnitHeight] = useState("metric")
  const [unitWeight] = useState("metric")
  const [gender, setGender] = useState("")
  const [age, setAge] = useState("")

  // calculated
  const [isValid, setIsValid] = useState(false)
  const [idealWeightMin, setIdealWeightMin] = useState(0)
  const [idealWeightMax, setIdealWeightMax] = useState(0)
  const [maxOverweight, setMaxOverweight] = useState(0)
  const [maxObeseWeight, setMaxObeseWeight] = useState(0)
  const [bmi, setBmi] = useState(0)

  // ui
  const [showError, setShowError] = useState(false)
  const [showResult, setShowResult] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const [shouldScroll, setShouldScroll] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    calculate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    gender,
    age,
    unitHeight,
    heightCm,
    showResult,
    heightFt,
    heightIn,
    submitted,
    showError,
  ])

  useEffect(() => {
    if (shouldScroll) {
      const anchor = document.querySelector("#result-container")
      if (anchor) {
        setTimeout(() => {
          anchor.scrollIntoView({ behavior: "smooth", block: "start" })
        }, 50)
      }
      setShouldScroll(false)
    }
  }, [shouldScroll])

  const handleUnitHeightChange = value => {
    setUnitHeight(value)
  }

  const handleGenderChange = value => {
    setGender(value)
    const newErrors = errors.filter(e => e !== "gender")
    setErrors(newErrors)
  }

  const handleAgeChange = event => {
    const newAge = parseInt(event.target.value)
    if (Number.isInteger(newAge)) {
      setAge(event.target.value)
      const newErrors = errors.filter(e => e !== "age")
      setErrors(newErrors)
    } else {
      setAge("")
    }
  }

  const handleMetricHeightChange = event => {
    const newHeight = parseInt(event.target.value)
    if (Number.isInteger(newHeight)) {
      setHeightCm(event.target.value)
      const newErrors = errors.filter(e => e !== "height")
      setErrors(newErrors)
    } else {
      setHeightCm("")
    }
  }

  const handleImperialHeightChange = (part, value) => {
    const newHeight = parseInt(value)

    if (Number.isInteger(newHeight)) {
      if (part === "ft") {
        setHeightFt(value)
      }

      if (part === "in") {
        setHeightIn(value)
      }
      const newErrors = errors.filter(e => e !== "height")
      setErrors(newErrors)
    } else {
      if (part === "ft") {
        setHeightFt("")
      }

      if (part === "in") {
        setHeightIn("")
      }
    }
  }

  const validate = () => {
    const newErrors = []
    if (!gender) {
      newErrors.push("gender")
    }
    if (!age) {
      newErrors.push("age")
    }

    if (unitHeight === "metric") {
      if (!heightCm) {
        newErrors.push("height")
      }
    }

    if (unitHeight === "imperial") {
      if (!heightFt || !heightIn) {
        newErrors.push("height")
      }
    }

    if (newErrors.length > 0 && submitted) {
      setShowError(true)
    }

    submitted && setErrors(newErrors)

    if (newErrors.length < 1) {
      setIsValid(true)
      return true
    } else {
      setIsValid(false)
      return false
    }
  }

  const setBmiRanges = (sizeSquared, weightFactor) => {
    let min = 19
    let max = 24
    if (age < 24) {
      min = 19
      max = 24
    }
    if (age < 34 && age > 23) {
      min = 20
      max = 25
    }
    if (age < 44 && age > 33) {
      min = 21
      max = 26
    }
    if (age < 54 && age > 43) {
      min = 22
      max = 27
    }
    if (age < 64 && age > 53) {
      min = 23
      max = 28
    }
    if (age >= 64) {
      min = 24
      max = 29
    }

    if (gender === "f") {
      min = min - 1
      max = max - 1
    }

    setIdealWeightMin(Math.round(min * sizeSquared * weightFactor * 10) / 10)
    setIdealWeightMax(Math.round(max * sizeSquared * weightFactor * 10) / 10)

    setBmi(Math.round(((min + max) / 2) * 10) / 10)

    setMaxOverweight(
      Math.round((max + 5) * sizeSquared * weightFactor * 10) / 10
    )

    setMaxOverweight(
      Math.round(((max + 5) * sizeSquared * weightFactor * 10) / 10)
    )
    setMaxObeseWeight(
      Math.round((max + 15) * sizeSquared * weightFactor * 10) / 10
    )
  }

  const calculate = () => {
    if (!validate()) {
      return false
    }

    if (errors.length > 0) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }

    let locHeightCm = heightCm
    if (unitHeight === "imperial") {
      let heightInches = heightFt * 12 + parseInt(heightIn)
      locHeightCm = heightInches * 2.54
      setHeightCm(heightInches * 2.54)
    }

    let sizeSquared = (locHeightCm / 100) * (locHeightCm / 100)
    let weightFactor = unitWeight === "metric" ? 1 : 2.20462
    setBmiRanges(sizeSquared, weightFactor)
  }

  const redirectToCalculatorFunnel = () => {
    setIsLoading(true)

    const params = {
      flowid: "calculator",
      calculator: "idealweight",
      idealWeightMin: idealWeightMin,
      idealWeightMax: idealWeightMax,
      weightUnit: unitWeight === "metric" ? "kg" : "lb",
      age: age,
      gender: gender === "m" ? "male" : "female",
      trial: "1",
      utm_source: `knet${i18next.language}`,
      utm_medium: "calculators",
      utm_campaign: "calculator-funnel",
      funnel_variant: "knet-calculator",
      lang: i18next.language,
    }

    if (unitHeight === "metric") {
      params.height = heightCm
    } else {
      params.ft = heightFt
      params.in = heightIn
    }

    setTimeout(() => {
      setIsLoading(false)

      window.location.href =
        "https://www.yazio.com/app/onboarding/calculator?" +
        new URLSearchParams(params).toString()
    }, 2000)
  }

  const handleShowResult = () => {
    setSubmitted(true)
    validate()
    setShowError(!isValid)

    if (isValid) {
      redirectToCalculatorFunnel()
    }
  }

  const handleKeyDown = event => {
    if (event.key === "Enter") {
      handleShowResult()
    }
  }

  return (
    <Layout pageName="start" theme={theme}>
      <Container onKeyDown={handleKeyDown}>
        <PageHeading
          title={t("calculator_page.ideal_weight.heading")}
          icon={PageHeadingIcon}
        >
          {t("calculator_page.ideal_weight.teaser.text", "")}
        </PageHeading>
        <Grid
          container
          sx={{ mt: theme.spacing(4) }}
          rowSpacing={1}
          columnSpacing={{ md: theme.spacing(2) }}
        >
          <Grid item xs={12} md={4}>
            <CalcInputBox
              error={errors.includes("gender")}
              num={1}
              title={t("calculator.general.label.gender")}
            >
              <GenderSwitch
                error={errors.includes("gender")}
                onInput={handleGenderChange}
                value={gender}
              />
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <CalcInputBox
              error={errors.includes("age")}
              num={2}
              title={t("calculator.general.label.age")}
            >
              <TextInput
                placeholder="21"
                label={""}
                type="number"
                error={errors.includes("age")}
                variant="standard"
                value={age}
                onChange={handleAgeChange}
              />
              <Typography
                variant="body1"
                component="div"
                sx={{
                  display: "inline-block",
                  lineHeight: { xs: "3rem", md: "3.125rem" },
                }}
              >
                {t("calculator.general.label.years")}
              </Typography>
            </CalcInputBox>
          </Grid>
          <Grid item xs={12} md={4}>
            <CalcInputBox
              error={errors.includes("height")}
              num={3}
              title={t("calculator.general.label.height")}
            >
              {unitHeight === "metric" ? (
                <>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="170"
                    value={heightCm}
                    type="number"
                    onChange={handleMetricHeightChange}
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.cm", "cm")}
                  </Typography>
                </>
              ) : (
                <>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="5"
                    type="number"
                    value={heightFt}
                    onChange={event =>
                      handleImperialHeightChange("ft", event.target.value)
                    }
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.ft", "ft")}
                  </Typography>
                  <TextInput
                    error={errors.includes("height")}
                    placeholder="6"
                    type="number"
                    value={heightIn}
                    onChange={event =>
                      handleImperialHeightChange("in", event.target.value)
                    }
                    label={""}
                  />
                  <Typography
                    variant="body1"
                    component="span"
                    sx={{
                      display: "inline-block",
                      lineHeight: { xs: "3rem", md: "3.125rem" },
                    }}
                  >
                    {t("system.general.unit.in", "in")}
                  </Typography>
                </>
              )}

              <Grid
                container
                direction="row"
                justifyContent="center"
                columnSpacing={2}
                mt={2}
              >
                <Grid item xs={4} textAlign="right">
                  <StyledButton
                    disableElevation
                    color={errors.includes("height") ? "error" : "secondary"}
                    onClick={() => handleUnitHeightChange("metric")}
                    variant={unitHeight === "metric" ? "contained" : "outlined"}
                  >
                    {t("system.general.unit.cm", "cm")}
                  </StyledButton>
                </Grid>
                <Grid item xs={4} textAlign="left">
                  <StyledButton
                    disableElevation
                    color={errors.includes("height") ? "error" : "secondary"}
                    variant={
                      unitHeight === "imperial" ? "contained" : "outlined"
                    }
                    onChange={handleUnitHeightChange}
                    onClick={() => handleUnitHeightChange("imperial")}
                  >
                    {t("system.general.unit.ft", "ft")}/
                    {t("system.general.unit.in", "in")}
                  </StyledButton>
                </Grid>
              </Grid>
            </CalcInputBox>
          </Grid>

          <Grid item xs={12} textAlign="center">
            {showError && (
              <Alert
                onClose={() => {
                  setShowError(false)
                }}
                variant="outlined"
                icon={false}
                sx={{
                  mt: theme.spacing(2),
                  backgroundColor: theme.palette.error.light,
                  color: theme.palette.error.dark,
                  fontSize: { xs: "1rem" },
                  lineHeight: { xs: "1.5rem" },
                }}
                severity="error"
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: t("calculator.general.message.complete_form"),
                  }}
                />
              </Alert>
            )}

            <Button
              fullWidth={!upMd}
              size="large"
              variant="contained"
              onClick={handleShowResult}
              sx={{
                backgroundColor: theme.palette.secondary.dark,
                mt: { xs: 6, md: 4 },
                width: { md: 360 },
                "&:hover": {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
                "&.MuiButton-contained.Mui-focusVisible": {
                  backgroundColor: theme.palette.secondary.dark, // same as the non-hover color
                },
              }}
              disableElevation
            >
              {isLoading ? <LoadingIndicator /> : ""}
              {t("calculator.ideal_weight.button.calculate")}
            </Button>
          </Grid>
        </Grid>
      </Container>
      <Container
        id={"result-container"}
        style={{
          display: showResult && submitted && isValid ? "block" : "none",
        }}
      >
        <Container>
          <Typography
            sx={{
              fontWeight: 600,
              textAlign: "center",
              my: theme.spacing(6),
            }}
            variant="h5"
          >
            {t("calculator.general.headline.result")}
          </Typography>
        </Container>
        <Container maxWidth="lg" disableGutters={!upMd} sx={{}}>
          <RangeBar
            //idealWeightMin={idealWeightMin}
            idealWeightMin={new Intl.NumberFormat(i18next.language).format(
              idealWeightMin
            )}
            idealWeightMax={new Intl.NumberFormat(i18next.language).format(
              idealWeightMax
            )}
            maxOverweight={new Intl.NumberFormat(i18next.language).format(
              maxOverweight
            )}
            maxObeseWeight={new Intl.NumberFormat(i18next.language).format(
              maxObeseWeight
            )}
            isMetric={unitWeight === "metric"}
            type="ideal-weight"
          />
        </Container>
        <Container>
          {i18next.exists("calculator_page.ideal_weight.bottom.text") && (
            <Textblock mt={6}>
              <div
                dangerouslySetInnerHTML={{
                  __html: t("calculator_page.ideal_weight.bottom.text"),
                }}
              />
            </Textblock>
          )}
          <AdAppCounting mt={6} />
        </Container>
      </Container>
    </Layout>
  )
}

export default withTrans(CalcIdealWeight)

export const Head = () => (
  <Seo title={i18next.t("calculator_page.ideal_weight.title")} />
)
