import { Box, Grid, Typography, useMediaQuery } from "@mui/material"
import { Container } from "@mui/system"
import * as React from "react"
import { useEffect } from "react"
import theme from "../theme"
import Bubble from "./Bubble"
import MyStepper from "./MyStepper"
import withTrans from "./withTrans"

const RangeBar = props => {
  const { idealWeightMin, idealWeightMax, isMetric, bmi, t, type } = props
  const bmiWithPeriod = bmi ? parseFloat(bmi.replace(",", ".")) : null;
  const underweightRef = React.useRef(null);
  const healthyRef = React.useRef(null);
  const overweightRef = React.useRef(null);
  const obeseRef = React.useRef(null);
  const morbidRef = React.useRef(null);

  const containerRef = React.useRef(null)
  const [upMd] = React.useState(useMediaQuery(theme.breakpoints.up("md")))

  const scrollToNormalWeight = () => {
    const width = containerRef.current.clientWidth
    const rest = 360 - width / 2

    if (!upMd) {
      containerRef.current.scrollLeft = rest
    }
  }

  useEffect(() => {
    setTimeout(scrollToNormalWeight, 150);
  }, [upMd]);

  const scrollToBubble = () => {
    let scrollToRef;

    if (bmiWithPeriod < 18.5) {
      scrollToRef = underweightRef
    } else if (bmiWithPeriod >= 18.5 && bmiWithPeriod <= 24.9) {
      scrollToRef = healthyRef
    } else if (bmiWithPeriod >= 25 && bmiWithPeriod <= 29.9) {
      scrollToRef = overweightRef
    } else if (bmiWithPeriod >= 30 && bmiWithPeriod <= 39.9) {
      scrollToRef = obeseRef
    } else if (bmiWithPeriod >= 40) {
      scrollToRef = morbidRef
    }

    if (scrollToRef && scrollToRef.current) {
      scrollToRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  useEffect(() => {
    if (bmiWithPeriod) {
      scrollToBubble();
    }
  }, [bmiWithPeriod]);

  return (
    <>
      <Grid
        container
        sx={{
          overflowX: { xs: "scroll", md: "visible" },
          width: { xs: "100vw", md: "100%" },
          whiteSpace: { xs: "nowrap", md: "normal" },
          scrollBehavior: "smooth",
        }}
        ref={containerRef}
        textAlign={"center"}
      >
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="flex-end"
          textAlign={"center"}
          spacing={0}
          sx={{
            minWidth: { xs: 1200, md: "100%" },
            px: { xs: 0, md: theme.spacing(2) },
          }}
        >
          <Grid
            item
            ref={underweightRef}
            sx={{
              borderBottom: "8px solid #FEC84B",
            }}
            xs
          >
            {type !== 'ideal-weight' && bmiWithPeriod < 18.5 && <Bubble {...props} color={"#FEC84B"} />}
            <Typography variant="body2" sx={{ pb: theme.spacing(1) }}>
              {t("calculator.general.label.underweight")}
            </Typography>
          </Grid>
          <Grid
            item
            ref={healthyRef}
            sx={{
              borderBottom: "8px solid #3CCB7F",
            }}
            xs
          >
            {(type === 'ideal-weight' || (bmiWithPeriod >= 18.5 && bmiWithPeriod <= 24.9)) && (
              <Bubble {...props} color={"#3CCB7F"} />
            )}
            <Typography variant="body2" sx={{ pb: theme.spacing(1) }}>
              {t("calculator.general.label.healthy")}
            </Typography>
          </Grid>
          <Grid
            item
            ref={overweightRef}
            sx={{
              borderBottom: "8px solid #FEC84B",
            }}
            xs
          >
            {bmiWithPeriod >= 25 && bmiWithPeriod <= 29.9 && (
              <Bubble {...props} color={"#FEC84B"} />
            )}
            <Typography variant="body2" sx={{ pb: theme.spacing(1) }}>
              {t("calculator.general.label.overweight")}
            </Typography>
          </Grid>
          <Grid
            item
            ref={obeseRef}
            sx={{
              borderBottom: "8px solid #F79009",
            }}
            xs
          >
            {bmiWithPeriod >= 30 && bmiWithPeriod <= 39.9 && (
              <Bubble {...props} color={"#F79009"} />
            )}
            <Typography variant="body2" sx={{ pb: theme.spacing(1) }}>
              {t("calculator.general.label.obese")}
            </Typography>
          </Grid>
          <Grid
            item
            ref={morbidRef}
            sx={{
              borderBottom: "8px solid #BA1B1B",
            }}
            xs
          >
            {bmiWithPeriod >= 40 && <Bubble {...props} color={"#BA1B1B"} />}
            <Typography variant="body2" sx={{ pb: theme.spacing(1) }}>
              {t("calculator.general.label.morbid")}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="space-around"
          textAlign={"right"}
          spacing={0}
          sx={{ minWidth: 1200 }}
        >
          <Grid item xs={2}></Grid>
          <Grid item xs={8} mt={2}>
            <MyStepper {...props} />
          </Grid>
          <Grid item xs={2}></Grid>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="space-around"
        spacing={0}
        mt={6}
      >
        <Container>
          <Grid item xs={12} mb={6}>
            <Box
              alignSelf={"center"}
              textAlign={"center"}
              sx={{
                backgroundColor: "secondary.light",
                width: { xs: "100%", md: 360 },
                margin: "0 auto",
                borderRadius: "16px",
                color: theme.palette.text.secondary,
                px: { xs: theme.spacing(1) },
                py: { xs: theme.spacing(2) },
              }}
            >
              <Typography variant="body2">
                <strong>{t("calculator.bmi.label.your_bmi")}</strong>
                <br />
                {`${idealWeightMin} ${
                  isMetric ? "kg" : "lbs"
                }  - ${idealWeightMax} ${isMetric ? "kg" : "lbs"}`}
              </Typography>
            </Box>
          </Grid>
        </Container>
      </Grid>
    </>
  )
}

export default withTrans(RangeBar)
